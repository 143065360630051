import { graphql } from "gatsby"
import React, { useState } from "react"
import slugify from "slugify"
import {
  email,
  phoneNumber,
  tel,
  chat
} from "../../../components/footer/footer-constants"
import styles from "./ways-to-give.module.scss"
import { Page } from "@components/page/page"
import { defaultMetadata, PageOpenGraphImage } from "@core/constants"
import { SectionLinks } from "@features/about/components/section-links/section-links"
import { SupportBox } from "@features/about/components/support-box/support-box"
import { Button } from "@components/button/button"
import { Icon } from "@components/icon/icon"
import { AccordionItem } from "@components/accordion-item/accordion-item"
import { CtaNode } from "gatsby/config/create-pages/types"
import { ToHTMLFormatYoutubeEmbed } from "@components/to-html/to-html-format-youtube-embed"

export const WaysToGivePage = (props: any) => {
  const { data, ...rest } = props
  const details = data?.contentfulWaysToGive
  // These are the giving pages, we use the paths for those pages to
  // populate the navbar at the top of this ways-to-give page
  const childPages = data?.allContentfulGenericCtaPage?.nodes || []

  const childPageLinks = childPages.map((node: CtaNode) => {
    return {
      href: node.path,
      text: node.name,
      order: node.navigationOrder
    }
  })

  const sectionLinks = [
    ...childPageLinks,
    {
      href: "/give/outreach-projects",
      text: "Outreach Projects"
    }
  ]

  const supportBox = {
    title: details.hero.title || "",
    subtitle: details.hero.subtitle,
    image: details.hero.image.fluid.src || "",
    button: {
      text: details.hero.buttonText || "",
      url: details.hero.buttonUrl || ""
    }
  }

  const createAnchorHash = (base: string) =>
    slugify(base, {
      remove: /[*+~.()'"!:@]/g,
      lower: true,
      trim: true
    })

  const [anchorHashValue, setAnchorHashValue] = useState<string>("")

  if (typeof window !== "undefined") {
    const hashValue = window.location.hash.slice(1)

    if (hashValue !== anchorHashValue) {
      setAnchorHashValue(hashValue)
    }
  }

  return (
    <Page
      {...rest}
      metadata={{
        ...defaultMetadata,
        title: "Ways to Give | " + defaultMetadata.title,
        openGraphImage: PageOpenGraphImage.WaysToGive
      }}
    >
      <SectionLinks
        titleId="general-ways-to-give"
        sectionLinks={sectionLinks}
      />
      <SupportBox className={styles.supportBox} {...supportBox} />
      <section className={styles.description}>
        <div className={styles.content}>
          <ToHTMLFormatYoutubeEmbed
            text={details?.description?.childMarkdownRemark?.html}
          />
        </div>
      </section>
      {details?.accordions && (
        <section className={styles.accordion}>
          <div className={styles.accordionContent}>
            {details?.accordions.map(
              ({ title, subtitle, image, body }: AccordionItem) => {
                const anchorHashKey = createAnchorHash(title)

                return (
                  <div
                    className={styles.callout}
                    key={title}
                    id={anchorHashKey}
                  >
                    <AccordionItem
                      title={title}
                      subtitle={subtitle}
                      image={image?.fluid?.src}
                      body={body?.childMarkdownRemark?.rawMarkdownBody || ""}
                      defaultExpandState={anchorHashValue === anchorHashKey}
                    />
                  </div>
                )
              }
            )}
          </div>
        </section>
      )}
      <div className={styles.midPageCta}>
        <div className={styles.text}>
          <div className={styles.subtitle}>{details?.midPageCta?.subtitle}</div>
          <h3 className={styles.title}>{details?.midPageCta?.title}</h3>
          <ToHTMLFormatYoutubeEmbed
            className={styles.midPageCtaDescription}
            text={details?.midPageCta?.body?.childMarkdownRemark?.html}
          />
        </div>
        <div className={styles.button}>
          <Button
            size="sm"
            variant="primary"
            to={details?.midPageCta?.buttonUrl}
          >
            {details?.midPageCta?.buttonText}
          </Button>
        </div>
      </div>
      <div className={styles.explanationWrapper}>
        <div className={styles.explanation}>
          <img
            data-src={details?.explanation?.image?.fluid?.src}
            className="lazyload"
          />
          <h3 className={styles.title}>{details?.explanation?.title}</h3>
          <ToHTMLFormatYoutubeEmbed
            text={details?.explanation?.body?.childMarkdownRemark?.html}
          />
        </div>
      </div>
      <section className={styles.help}>
        <Icon className={styles.icon} variant="24-chat" />
        <h3 className={styles.heading}>How may we help?</h3>
        <p className={styles.helpText}>
          Our team would be happy to answer any questions you may have about our
          outreach or the ways you can help further the mission. Please contact
          us using the <a href={chat}>chat bubble</a>, <a href={email}>email</a>
          , or calling <a href={tel}>{phoneNumber}</a>.
        </p>
      </section>
    </Page>
  )
}

export default WaysToGivePage

export const query = graphql`
  query WaysToGivePage {
    # we filter for only the giving pages so we can populate the nav bar above
    # and then we sort based on the navigationOrder field
    allContentfulGenericCtaPage(
      sort: { fields: [navigationOrder], order: ASC }
      filter: { type: { eq: "giving" } }
    ) {
      nodes {
        id
        path
        name
      }
    }
    contentfulWaysToGive {
      id
      hero {
        title
        subtitle
        image {
          fluid {
            src
          }
        }
        buttonText
        buttonUrl
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      accordions {
        title
        subtitle
        image {
          fluid {
            src
          }
        }
        body {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      midPageCta {
        title
        subtitle
        body {
          childMarkdownRemark {
            html
          }
        }
        buttonText
        buttonUrl
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      explanation {
        title
        body {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid {
            src
          }
        }
      }
    }
  }
`
