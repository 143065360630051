import React, { FunctionComponent, useState } from "react"
import classNames from "classnames"
import { FormattedMessage } from "react-intl"
import { Icon } from "../../../../components/icon/icon"
import { noop } from "../../../../core/utils"
import styles from "./section-links.module.scss"

interface LinksProps {
  onClick: () => void
  sectionLinks: SectionLink[]
}

export interface SectionLink {
  href: string
  messageId?: string
  text?: string
}

export interface SectionLinkProps {
  sectionLinks: SectionLink[]
  titleId: string
}
const Links: FunctionComponent<LinksProps> = ({ onClick, sectionLinks }) => (
  <ul className={styles.links}>
    {sectionLinks.map(({ href, messageId, text }) => {
      return (
        <li key={href}>
          <a href={href} onClick={onClick}>
            {messageId ? <FormattedMessage id={messageId} /> : <>{text}</>}
          </a>
        </li>
      )
    })}
  </ul>
)

export const SectionLinks: FunctionComponent<SectionLinkProps> = ({
  sectionLinks,
  titleId
}) => {
  const [isOpen, setOpen] = useState(false)
  const toggleOpenState = () => setOpen((isOpen) => !isOpen)

  return (
    <div className={styles.sectionLinks}>
      <div className={styles.desktop}>
        <Links sectionLinks={sectionLinks} onClick={noop} />
      </div>
      <div className={styles.mobile}>
        <div
          className={classNames(styles.header, isOpen && styles.open)}
          onClick={toggleOpenState}
        >
          <FormattedMessage id={titleId} />
          <Icon variant="16-right-arrow" className={styles.icon} />
        </div>
        {isOpen && (
          <Links sectionLinks={sectionLinks} onClick={toggleOpenState} />
        )}
      </div>
    </div>
  )
}
